<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form @submit.prevent="handleSubmit(saveRole)">
			<header class="modal-card-head">
				<h4 class="modal-card-title">
					<span translate="no">{{ modalTitle(name) }} <strong>Role</strong></span>
					<span v-if="permission.id">#{{ permission.id }}</span>
				</h4>
			</header>
			<div class="modal-card-body">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
				<div class="modal-card">
					<div class="columns is-multiline is-mobile mb-2">
						<div class="column is-12-mobile is-4-tablet">
							<InputWithValidation label="Name" size="is-medium" v-model="permission.name" :disabled="name == 'Edit'" />
						</div>
						<div class="column is-12-mobile is-7-tablet">
							<InputWithValidation rules="required|min:3" type="text" label="Description" size="is-medium" v-model="permission.description" />
						</div>
						<div class="column is-12-mobile is-1-tablet">
							<b-field label="Color" class="mb-2">
								<v-swatches v-model="permission.color" :swatches="swatches" row-length="5" shapes="circles" popover-x="left"></v-swatches>
							</b-field>
						</div>
					</div>
					<h3 class="is-size-5 is-medium has-text-primary">Permissions</h3>
					<hr class="my-3" />
					<div class="columns is-multiline is-mobile">
						<div class="column is-hidden-mobile is-4-tablet"></div>
						<div v-for="(role, i) in crud" :key="i" class="column is-2-tablet text-center">
							<span class="is-size-7 text-uppercase is-medium action" @click="selectAll(role.label)">{{ role.name }}</span>
						</div>
					</div>
					<span class="item" v-for="(r, i) in permission.route" :key="i">
						<div class="columns is-multiline is-mobile mb-0">
							<div class="column is-12-mobile is-4-tablet">
								<h4 class="is-size-6 is-medium has-text-primary" translate="no">{{ r.name }}</h4>
							</div>
							<div class="column is-2-tablet text-center" v-for="c in crud" :key="c.label">
								<b-checkbox type="is-secondary" v-model="r.role[c.label]" :name="r.name.toLowerCase() + '_' + c.label" class="ml-4"></b-checkbox>
								<input type="hidden" :value="r.slug" />
							</div>
						</div>
						<hr class="mt-0 mb-4" />
					</span>

					<small class="modal-updated" v-if="permission.updated_at">Last Update: {{ format(permission.updated_at) }}</small>
				</div>
			</div>
			<footer class="modal-card-foot">
				<b-button class="is-rounded is-outlined is-danger" @click="$emit('close')">Close</b-button>
				<b-button native-type="submit" class="is-rounded is-primary" :loading="loading">Save</b-button>
			</footer>
		</form>
	</ValidationObserver>
</template>

<script>
import InputWithValidation from '@/components/inputs/InputWithValidation'
import VSwatches from 'vue-swatches'
import { ValidationObserver } from 'vee-validate'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorToast } from '@/mixins/toast'
import '@/mixins/generic'

export default {
	components: {
		InputWithValidation,
		ValidationObserver,
		VSwatches
	},
	props: {
		id: {
			type: [Number, String],
			required: false
		},
		name: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			isOpening: false,
			loading: false,
			swatches: ['#feca57', '#ff9f43', '#ee5253', '#f368e0', '#b53471', '#7202f8', '#10ac84', '#01a3a4', '#0abde3', '#2e86de', '#341f97', '#dbdbdb', '#8395a7', '#576574', '#222f3e'],
			crud: [
				{
					label: 'edit',
					name: 'Edit'
				},
				{
					label: 'read',
					name: 'Read'
				},
				{
					label: 'create',
					name: 'Create'
				},
				{
					label: 'delete',
					name: 'Delete'
				}
			],
			permission: {
				name: '',
				description: '',
				color: '#dbdbdb',
				active: true,
				route: []
			}
		}
	},
	methods: {
		async selectAll(role) {
			let c = role.toLowerCase()

			this.permission.route.forEach(r => {
				r.role[c] = !r.role[c]
			})
		},
		async saveRole() {
			this.name === 'New' ? await this.store() : await this.update()
		},
		async store() {
			try {
				this.loading = true
				const response = await Api.post('permission/store', this.permission)
				const { status } = response
				if (status === 201 || status === 200) {
					// const { message } = response.data
					this.$emit('close')
					history.pushState({}, '', '/roles')
					successToast('The role was <strong>successfully</strong> created!')
					eventHub.$emit('reload-roles')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					// const { message } = e.data
					errorToast('An <strong>error</strong> occurred while creating the role!')
				}
			} finally {
				this.loading = false
			}
		},
		async update() {
			try {
				this.loading = true
				const response = await Api.put(`permission/update/${this.id}`, this.permission)
				const { status } = response
				if (status === 200) {
					// const { message } = response.data
					this.$emit('close')
					history.pushState({}, '', '/roles')
					successToast('The role has been <strong>successfully</strong> updated!')
					eventHub.$emit('reload-roles')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					// const { message } = e.data
					errorToast('An <strong>error</strong> has occurred while updating the role!')
				}
			} finally {
				this.loading = false
			}
		},
		async findById() {
			if (this.name === 'Edit') {
				this.isOpening = true
				try {
					const response = await Api.get(`permission/findById/${this.id}`)
					const { status } = response
					if (status === 200) {
						const { data } = response
						this.permission = data
						this.permission.route = JSON.parse(data.route)
						this.isOpening = false
					}
				} catch (e) {
					console.log(e)
				}
			}
			else {
				this.populatePermissionRoute();
			}
		},
		populatePermissionRoute() {
			var menus = ['Dashboard', 'Users', 'Roles', 'Categories', 'Dealers', 'Ratings'];
			for (var i in menus) {
				if (!Array.isArray(this.permission.route)) continue

				this.permission.route.push({
					name: menus[i],
					role: {
						edit: false,
						read: false,
						create: false,
						delete: false,
					},
					slug: menus[i].toLowerCase()
				})
			}
		}
	},
	mounted() {
		this.findById()
	}
}
</script>
